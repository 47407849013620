<template>
  <div class="banner">
    <div class="swiper-container1">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide swiper-no-swiping"
          v-for="(item, index) in images[val]"
          :key="index"
        >
          <img class="bg" :src="item.img" alt="" />
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
      <!-- 如果需要导航按钮 -->
      <!-- <div class="swiper-button-prev1"></div> -->
      <!-- <div class="swiper-button-next1"></div> -->
    </div>
    <slot name="banner"></slot>
  </div>
</template>

<script>
import { getScreenHeight } from "@/utils/index";
export default {
  name: "Banner",
  props: {
    val: {
      type: Number,
      default: 0,
    },
  },
  created() {},
  mounted() {
    this.heights = getScreenHeight();

    this.getBanner();
  },
  data() {
    return {
      heights: "",
      images: [],
    };
  },
  watch: {
    images: {
      handler(val) {
        console.log(val, "val");

        this.$nextTick(() => {
          let obj = {
            slidesPerView: 1, //一次显示几个
            centeredSlides: true, //居中
            loop: false, //循环
            autoplay: 3000, //自动播放
            noSwiping: true, //禁止滑动
            prevButton: ".swiper-button-prev1",
            nextButton: ".swiper-button-next1",
            
          };
          if (this.val == 0) {
            obj = {
              ...obj,
              pagination: ".swiper-pagination",
              paginationClickable: true,
              paginationBulletRender: function (swiper, index, className) {
                return (
                  '<div class="' +
                  className +
                  '">' +
                  "0" +
                  (index + 1) +
                  "</div>"
                );
              },
            };
          }
          var bannerswiper = new Swiper(".swiper-container1", obj);
          // 为.swiper-pagination-bullet添加hover事件
          let bullets = document.getElementsByClassName(
            "swiper-pagination-bullet"
          );
          for (var i = 0; i < bullets.length; i++) {
            bullets[i].onmouseover = function () {
              this.click();
            };
            bullets[i].onmouseout = function () {
              bannerswiper.startAutoplay();
            };
          }
        });
      },
      deep: true,
    },
  },
  methods: {
    // 获取首页banner图
    getBanner() {
      this.$axios.get("/api/lunbotu/").then((res) => {
        console.log(res, "获取的轮播图");
        this.images = [
          res.results,
          [{ img: require("../assets/banner/2.jpg") }],
          [{ img: require("../assets/banner/3.png") }],
          [{ img: require("../assets/banner/4.png") }],
          [{ img: require("../assets/banner/5.png") }],
          [{ img: require("../assets/banner/6.png") }],
          [{ img: require("../assets/banner/7.png") }],
        ];
        console.log(this.images, "获取的轮播图");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 700px;
  background-color: #1d1d1d;
  overflow: hidden;
  position: relative;

  .swiper-container1 {
    width: 100%;
    height: 100%;
    .swiper-slide {
      width: 100%;
      height: auto;
    }
  }

  .bg {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
    // 图片居中显示
    object-position: center;
  }

  ::v-deep .swiper-pagination-bullet {
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
  }
}
</style>