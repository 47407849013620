
// 判断是否是移动端
export function isMobile() {
    // 判断是否是移动端
    if (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iPad|Mobile)/i)) {
        return true;
    } else {
        return false;
    }
}

// 获取一屏的高度
export function getScreenHeight() {
    let heights = "100%";
    // 判断是否是移动端
    if (isMobile()) {
        // 如果是移动端，就把高度设置为屏幕的高度
        heights = "100%";
    } else {
        // 如果是PC端，就把高度设置为屏幕的高度
        const deviceWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        const scale = deviceWidth / 1920;
        // 视口高度缩小到原来的 1 / scale 倍
        heights = document.documentElement.clientHeight / scale + "px";

    }
    return heights;
}

// 检查元素是否在可视区域
export function checkVisible(el) {
    const rect = el.getBoundingClientRect();
    const viewHeight = document.documentElement.clientHeight;
    // rect.height > 0 说明元素是可见的
    return rect.top < viewHeight && rect.height > 0;
}

// 过滤富文本中的文字
export function filterRichText(str) {
    if (str) {
        return str.replace(/<[^>]+>/g, "");
    }
    return "";
}