import axios from "axios";
import { Message, Loading } from 'element-ui'

const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: "https://www.sairun028.com/front_end",
    // baseURL: "/backstage",
    // 超时
    timeout: 10000,
});

let loadingInstance; // Loading 实例

// request拦截器
service.interceptors.request.use(config => {
    return config
}, error => {
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    if (code === 0) {
        Promise.resolve(res.data)
    } else if (code === 401) {
    } else if (code === 500) {
        Message.error(res.data.msg);
    } else if (code === 601) {
        return Promise.reject('error')
    } else if (code !== 200) {
        return Promise.reject('error')
    } else {
        return res.data
    }
},
    error => {
        console.log('err' + error)
        return Promise.reject(error)
    }
)

export default service