<template>
  <div class="footpage">
    <div class="top">
      <div class="foot1">
        <div>
          <img src="../assets/logo.png" alt="" />
          <span>赛润科技</span>
        </div>
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="foot3">
        <span>联系我们</span>
        <div class="list">
          <div>{{ objinfo.lxwm_one }}</div>
          <div>{{ objinfo.lxwm_two }}</div>
          <div>{{ objinfo.lxwm_three }}</div>
          <div>{{ objinfo.lxwm_four }}</div>
        </div>
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="foot3">
        <span>相关链接</span>
        <div class="list">
          <div>
            <a :href="objinfo.xglj_one.url" target="_blank">{{
              objinfo.xglj_one.bt
            }}</a>
          </div>
          <div>
            <a :href="objinfo.xglj_two.url" target="_blank">{{
              objinfo.xglj_two.bt
            }}</a>
          </div>
          <div>
            <a :href="objinfo.xglj_three.url" target="_blank">{{
              objinfo.xglj_three.bt
            }}</a>
          </div>
          <div>
            <a :href="objinfo.xglj_four.url" target="_blank">{{
              objinfo.xglj_four.bt
            }}</a>
          </div>
        </div>
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="foot3">
        <span>关注我们</span>
        <div class="list">
          <div>{{ objinfo.gzwm_one }}</div>
          <div>{{ objinfo.gzwm_two }}</div>
          <img :src="objinfo.gzwm_three" alt="" />
        </div>
      </div>
    </div>
    <div class="bt">
      版权所有 @四川赛润科技有限公司
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
        >蜀ICP备2023018462号-1</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Foots",
  data() {
    return {
      objinfo: {},
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    // 获取数据
    getdata() {
      this.$axios
        .get("/api/dibucaidanindex/", {
          params: {
            page: 1,
            size: 1,
          },
        })
        .then((res) => {
          console.log(res);
          this.objinfo = res.results[0];
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.footpage {
  width: 100%;
  min-height: 435px;
  background-color: #1d1d1d;
  padding: 66px 0;
  scroll-snap-align: start;
  scroll-snap-stop: always;

  .top {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    .foot1 {
      div:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 33px;

        img {
          width: 49px;
          height: 54px;
        }

        span {
          font-size: 20px;
          color: #fff;
          margin-left: 10px;
        }
      }
    }

    .el-divider {
      height: 100%;
      margin: 0 50px;
    }

    .foot3 {
      width: 300px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      span {
        font-size: 22px;
        color: #fff;
        margin-bottom: 44px;
      }

      .list {
        div {
          font-size: 20px;
          color: rgba($color: #fff, $alpha: 0.29);
          margin-bottom: 20px;

          a {
            color: rgba($color: #fff, $alpha: 0.29);
          }

          a:hover {
            color: #fff;
            text-decoration: underline;
          }
        }
      }

      img {
        width: 149px;
        height: 152px;
      }
    }
  }

  .bt {
    width: 100%;
    height: 83px;
    line-height: 83px;
    text-align: center;
    font-size: 22px;
    color: #fff;
    background-color: #000;
    margin-top: 50px;
    a {
      color: #fff;
    }
    a:hover {
      color: blue;
      text-decoration: underline;
    }
  }

  a {
    text-decoration: none;
  }
}
</style>