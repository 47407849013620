<template>
  <div class="headpage">
    <div class="headmain">
      <div class="l">
        <img src="../assets/logo.png" alt="" />
        <span>赛润科技</span>
      </div>
      <div class="r">
        <el-menu
          :default-active="activeIndex"
          mode="horizontal"
          background-color="transparent"
          text-color="#fff"
          active-text-color="#fff"
          router
          @select="handleSelect"
        >
          <el-menu-item :index="patchdata[0]">首页</el-menu-item>
          <el-menu-item :index="patchdata[1]">关于赛润</el-menu-item>
          <el-menu-item :index="patchdata[2]">业务领域</el-menu-item>
          <el-menu-item :index="patchdata[3]">旗下产业</el-menu-item>
          <el-menu-item :index="patchdata[4]">产品中心</el-menu-item>
          <el-menu-item :index="patchdata[5]">新闻中心</el-menu-item>
          <el-menu-item :index="patchdata[6]">联系我们</el-menu-item>
          <!-- <el-menu-item index="/sjz">时间轴</el-menu-item> -->
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Heads",
  data() {
    return {
      activeIndex: "/",
      patchdata: [
        "/",
        "/about",
        "/business",
        "/industry",
        "/product",
        "/responsibility",
        "/contact",
      ],
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        let url = val.path.split("/")[1];
        this.activeIndex = `/${url}`;
      },
      immediate: true,
    },
  },
  created() {
    console.log(this.$route);
    this.activeIndex = this.$route.path;
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.defaultActive = key;
    },
  },
};
</script>

<style lang="scss" scoped>
.headpage {
  width: 100vw;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  .headmain {
    width: 1600px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .l {
    display: flex;
    align-items: center;

    img {
      width: 50px;
      height: 55px;
      margin-right: 18px;
    }

    span {
      font-size: 24px;
      color: #fff;
    }
  }

  .r {
    // ::v-deep .el-dropdown-menu__item,
    // .el-menu-item {
    //   font-size: 1.6rem;

    //   @media screen and (max-width: 750px) {
    //     padding: 0 5rem;
    //   }
    // }

    ::v-deep .el-menu.el-menu--horizontal {
      border-bottom: none;
    }

    // ::v-deep .el-menu--horizontal > .el-menu-item {
    //   height: 6rem;
    //   line-height: 6rem;
    // }
  }

  // ::v-deep .el-menu::after {
  //   display: none;
  // }
}
</style>