import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/utils/rem.js'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
ElementUI.Dialog.props.closeOnClickModal.default = false;
Vue.use(ElementUI, { size: 'Medium', zIndex: 3000 });

import 'vue-fullpage/vue-fullpage.css'
import VueFullpage from 'vue-fullpage'
Vue.use(VueFullpage)

import 'animate.css';

import Banner from "@/components/Banner.vue";
import Foots from "./components/Foots.vue";
import Heads from "./components/Heads.vue";
Vue.component("Banner", Banner);
Vue.component("Foots", Foots);
Vue.component("Heads", Heads);

import axios from "./utils/request";
Vue.prototype.$axios = axios;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
