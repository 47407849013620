<template>
  <div id="app">
    <Heads />
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {},
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100vw;
  height: 100%;
}

html {
  height: -webkit-fill-available;
}

body {
  
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

div {
  box-sizing: border-box;
}

.contentpage {
  width: 100vw;
  background-color: #fff;
}
.banner-content {
  z-index: 999;
}
// 全局滚动条样式
::-webkit-scrollbar {
  width: 0;
  height: 0;
  // background-color: #999;
}
</style>
