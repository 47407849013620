// Desc: rem适配
const designWidth = 1920; // 设计图的宽度

const baseSize = 16
// 设置 rem 函数
function setRem() {
    const deviceWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const scale = deviceWidth / designWidth;

    // postcss-pxtorem的rootValue设置为192,
    // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
    document.documentElement.style.fontSize = baseSize * Math.min(scale, 1) + 'px'

}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
    setRem()
}
